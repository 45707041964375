@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 /* background-image: url("../public/images/bg-tablet-pattern.svg");
  background-repeat: no-repeat;
  background-size: 800px;
  background-position: 80% -20%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#cta {
  background-image: url("../public/images/bg-simplify-section-desktop.svg");
  background-repeat: no-repeat;
}

@media (max-width: 576px) {
/*  body {
    background-position: 50px -50px;
    background-size: 500px;
  }
*/

  #cta {
    background-image: url("../public/images/bg-simplify-section-mobile.svg");
  }
}

/* Hamburger Menu */

.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

/* Create each line of the burger icon */
.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background: black;
  transform: rotate(0);
  transition: all 0.5s;
}

/* Move the middle line */
.hamburger-middle {
  transform: translateY(7px);
}

/* Move the bottom line */
.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}
